<template>
  <div class="ai-prediction">
    <div class="prediction-title">
      <div class="prediction-left-line">
        {{
          mark == "sd"
            ? "水稻"
            : mark == "dd"
            ? "大豆"
            : mark == "xm"
            ? "小麦"
            : mark == "dp"
            ? "大棚"
            : ""
        }}病虫害预报
      </div>
    </div>
    <AiPredictionChart :mark="mark"></AiPredictionChart>
    <AiPredictionList :mark="mark"></AiPredictionList>
  </div>
</template>

<script>
export default {
  name: "aiPrediction",
  components: {
    AiPredictionChart: () => import("./aiPredictionChart.vue"),
    AiPredictionList: () => import("./aiPredictionList.vue"),
  },
  props: {
    mark: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      chartData: {},
    };
  },
};
</script>

<style lang="less" scoped>
.ai-prediction {
  width: 372px;
  height: 100%;
  background: #142d4c;
  padding: 53px 48px;
  margin: 0 8px 0 0;
  .prediction-title {
    width: 100%;
    display: flex;
    margin-bottom: 25px;
    padding-right: 30px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    .prediction-left-line {
      font-size: 18px;
      font-weight: 400;
      color: #fff;
      padding: 0 0 0 15px;
      position: relative;

      &::before {
        content: "";
        background-color: #3e90e5;
        width: 4px;
        height: 18px;
        border-radius: 2px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
      }
    }
  }
  .prediction-eChart {
    width: 100%;
    height: 350px;
  }
}
</style>
