import { render, staticRenderFns } from "./aiPrediction.vue?vue&type=template&id=0619b3bc&scoped=true&"
import script from "./aiPrediction.vue?vue&type=script&lang=js&"
export * from "./aiPrediction.vue?vue&type=script&lang=js&"
import style0 from "./aiPrediction.vue?vue&type=style&index=0&id=0619b3bc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0619b3bc",
  null
  
)

export default component.exports